import { Injectable } from '@angular/core';
import { Commands, Facade } from '@w11k/tydux';
import { RecipeWithIngredientsEntity, RecipeWithScore } from '../../../types';
import { UserService } from './user.service';

export const createUserState = () => ({
    user: null as any,
    savedRecipes: [] as RecipeWithScore[],
});

export type UserState = ReturnType<typeof createUserState>;

class UserCommands extends Commands<UserState> {
    setUser(user: any) {
        this.state = {...this.state, user: user};
    }

    setSavedRecipes(recipes: RecipeWithScore[]) {
        this.state = {...this.state, savedRecipes: recipes};
    }

    removeSavedRecipe(recipe: RecipeWithIngredientsEntity) {
        this.state = {
            ...this.state,
            savedRecipes: this.state.savedRecipes.filter(c => c.id !== recipe.id),
        };
    }
}

@Injectable({
    providedIn: 'root',
})
export class UserFacade extends Facade<UserCommands> {
    constructor(
        private readonly user: UserService,
    ) {
        super('user', new UserCommands(), createUserState());
    }

    async getSavedRecipes() {
        const savedRecipes = await this.user.getSavedRecipes();
        return this.setSavedRecipes(savedRecipes);
    }

    setSavedRecipes(recipes: any) {
        this.commands.setSavedRecipes(recipes);
        return this.state.savedRecipes;
    }

    isRecipeSaved(recipe: RecipeWithIngredientsEntity): boolean {
        return !!this.state.savedRecipes.find((it: any) => it.id === recipe.id);
    }

    async saveRecipe(recipe: RecipeWithIngredientsEntity) {
        await this.user.saveRecipe(recipe);
        return await this.getSavedRecipes();
    }

    async removeSavedRecipe(recipe: RecipeWithIngredientsEntity) {
        await this.user.removeSavedRecipe(recipe);
        this.commands.removeSavedRecipe(recipe);
        return await this.getSavedRecipes();
    }

    async getCurrentUser(refresh: boolean = false) {
        if (!this.state.user) {
            this.commands.setUser(await this.user.getCurrentUser(refresh));
            return this.state.user;
        } else {
            return this.state.user;
        }
    }
}
