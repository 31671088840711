<ion-content>
    <div class="container">
        <client-recipe-card *ngIf="recipe"
                [recipe]="recipe"
                [linkToRoute]="false"
                enableSaving="enableSaving">
        </client-recipe-card>
    </div>

    <app-footer></app-footer>

</ion-content>
