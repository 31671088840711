<div class="RecipeBadges row">

    <div class="col-md-3 col-6" *ngIf="time">
        <div class="card mb-3 text-center">
            <div class="card-body time">
                <h5 class="card-title">Zeitaufwand</h5>
                <p class="card-text small-text">{{time}} min</p>
            </div>
        </div>
    </div>
<!--    <div class="col-md-3 col-6" *ngIf="manyGuests">-->
<!--        <div class="card mb-3 text-center">-->
<!--            <div class="card-body">-->
<!--                <h5 class="card-title">Viele Personen</h5>-->
<!--                <p class="card-text small-text">Für viele Gäste</p>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
    <div class="col-md-3 col-6" *ngIf="vegetarian && !vegan">
        <div class="card mb-3 text-center">
            <div class="card-body vegetarian">
                <h5 class="card-title">Vegetarisch</h5>
                <p class="card-text small-text">Rezept ohne Fleisch</p>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-6" *ngIf="vegan">
        <div class="card mb-3 text-center">
            <div class="card-body vegan">
                <h5 class="card-title">Vegan</h5>
                <p class="card-text small-text">Ohne tierische Produkte</p>
            </div>
        </div>
    </div>

<!--    <div class="col-md-3 col-6" *ngIf="hot">-->
<!--        <div class="card mb-3 text-center">-->
<!--            <div class="card-body vegan">-->
<!--                <h5 class="card-title">Schärfegrad</h5>-->
<!--                <p class="card-text small-text">TODO</p>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

</div>
