import { Component, Input } from '@angular/core';

@Component({
    selector: 'client-recipe-badges',
    templateUrl: './recipe-badges.component.html',
    styleUrls: ['./recipe-badges.component.scss'],
})
export class RecipeBadgesComponent {
    @Input() time?: number = undefined;
    @Input() manyGuests = false;
    @Input() vegetarian = false;
    @Input() vegan = false;
}
