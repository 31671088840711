import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PreloadAllModules, RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { DiscoverRecipesComponent } from './discover-recipes/discover-recipes.component';
import { ImprintComponent } from './imprint/imprint.component';
import { AboutUsComponent } from './aboutus/aboutus.component';
import { RecipePageComponent } from './recipe-page/recipe-page.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { DiscoverByTypeComponent } from './discover-by-type/discover-by-type.component';
import { SearchRecipesComponent } from './search-recipes/search-recipes.component';
import { HttpClientModule } from '@angular/common/http';
import { TyduxModule } from '@w11k/tydux-angular';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { CoreModule } from './core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { appRoutes } from './app.routes';
import { SavedRecipesComponent } from './saved-recipes/saved-recipes.component';
import { LoginComponent } from './login/login.component';
import { DiscoverByIngredientComponent } from './discover-by-ingredient/discover-by-ingredient.component';

@NgModule({
    declarations: [
        AppComponent,
        DiscoverRecipesComponent,
        ImprintComponent,
        AboutUsComponent,
        RecipePageComponent,
        PrivacyComponent,
        DiscoverByTypeComponent,
        DiscoverByIngredientComponent,
        SearchRecipesComponent,
        SavedRecipesComponent,
        LoginComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        IonicModule.forRoot({mode: 'md'}),
        RouterModule.forRoot(appRoutes, {
            initialNavigation: 'enabledBlocking',  preloadingStrategy: PreloadAllModules
        }),
        HttpClientModule,
        TyduxModule.forRootWithConfig({environment: environment, autoUseDevToolsInDevelopmentMode: false}),
        BrowserAnimationsModule,
        CommonModule,
        CoreModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        NgbNavModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
    ],
    providers: [{provide: RouteReuseStrategy, useClass: IonicRouteStrategy}],
    bootstrap: [AppComponent],
})
export class AppModule {
}
