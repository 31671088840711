import { PartialItem } from '@directus/sdk';

export type DirectusSchema = {
    ingredient: IngredientDTO,
    recipe: RecipeDTO,
    recipe_ingredient: RecipeIngredientMapping
}

export type RecipeDTO = {
    id: number;
    user_created?: string;
    date_created?: Date;
    user_updated: string;
    date_updated: Date;
    name: string;
    slug?: string;
    description?: string;
    recipeUrl?: string;
    image?: string;
    ingredients: RecipeIngredientMapping[];
    optionalIngredients: RecipeIngredientMapping[];
    type: RecipeTypeEntity;
    instructions?: string;
    instructionList?: {instruction: string}[];
    cuisine?: string;
    prepTime?: number;
    cookTime?: number;
    totalTime?: number;
    vegetarian?: boolean;
    vegan?: boolean;
    manyGuests?: boolean;
}

export type RecipeIngredientMapping = {
    ingredient_id?: IngredientDTO;
    recipe_id?: PartialItem<RecipeDTO>
}

export type IngredientDTO = {
    id: number;
    user_created?: string;
    date_created?: Date;
    user_updated?: string;
    date_updated?: Date;
    name: string;
    type?: IngredientTypeEntity;
    tags?: TagMapping[];
    vegan?: boolean
    vegetarian?: boolean
    description?: string;
}

export type RecipeEntity = {
    id: number;
    date_created?: Date;
    date_updated?: Date;
    name: string;
    subtitle?: string;
    slug?: string;
    description?: string;
    recipeUrl?: string;
    imageUrl?: string;
    image?: string;
    type?: RECIPE_TYPES;
    instructions?: string;
    instructionList?: {instruction: string}[];
    cuisine?: string;
    prepTime?: number;
    cookTime?: number;
    totalTime?: number;
    vegetarian?: boolean;
    vegan?: boolean;
    manyGuests?: boolean;
}

export type RecipeWithIngredientsEntity = RecipeEntity & {
    ingredients: IngredientEntity[];
    optionalIngredients: IngredientEntity[];
}

export type IngredientEntity = {
    id: number;
    name: string;
    description?: any;
    vegetarian?: boolean;
    vegan?: boolean;
    type: INGREDIENT_TYPES;
    tags: INGREDIENT_TYPES[];
    date_created?: any;
    date_updated?: any;
}

export type IngredientWithMappedRecipesEntity = IngredientEntity & {
    recipes: RecipeEntity[]
}

export type IngredientTypeEntity = {
    id: number;
    name: string;
}

export type RecipeTypeEntity = {
    id: number;
    name: string;
}

export type TagMapping = {
    id: number;
    ingredient_id: IngredientTypeEntity;
    IngredientType_id: IngredientTypeEntity;
}


export type RecipeWithScore = RecipeWithIngredientsEntity & {
    score: number
}

export enum RECIPE_TYPES {
    SOUP = 'SOUP',
    PIZZA = 'PIZZA',
    PASTA = 'PASTA',
    BURGER = 'BURGER',
    DISH = 'DISH',
    SALAD = 'SALAD',
    BREAKFAST = 'BREAKFAST',
    COLD_DISH = 'COLD_DISH',
    BAKED_DISH = 'BAKED_DISH'
}

export enum INGREDIENT_TYPES {
    CHEESE = 'CHEESE',
    NOODLES = 'NOODLES',
    RICE = 'RICE',
    MILK_PRODUCT = 'MILK_PRODUCT',
    VINEGAR = 'VINEGAR',
    OIL = 'OIL',
    VEGETABLE = 'VEGETABLE',
    SEASONING = 'SEASONING',
    BASIC_FOOD_ITEM = 'BASIC_FOOD_ITEM',
    NUT = 'NUT',
    WHIP = 'WHIP',
    CURRY = 'CURRY',
    ASIAN = 'ASIAN',
    MEAT = 'MEAT',
    FRUIT = 'FRUIT'
}
