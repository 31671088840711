import { Injectable } from '@angular/core';
import { Directus } from '@directus/sdk';
import { DirectusSchema, RecipeWithIngredientsEntity } from '../../../types';
import { environment } from '../../environments/environment';
import { convertToRecipeWithIngredients } from './recipe.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private directus: Directus<DirectusSchema> = new Directus<DirectusSchema>(environment.API_BASE_URL);
    private user: any = null;
    private user$ = new BehaviorSubject<any>(null);


    constructor() {
    }

    async getCurrentUser(refresh: boolean): Promise<Observable<any>> {
        try {
            if (refresh) {
                await this.refresh();
            }
            this.user = await this.directus.users.me.read();

        } catch (e) {
            console.info(e);
            await this.refresh();
        }
        console.info('user info', this.user);
        return this.user;
    }

    async saveRecipe(recipe: RecipeWithIngredientsEntity) {
        await this.directus.items('saved_recipes').createOne({
            user: this.user.id,
            recipe: recipe.id,
        });
    }

    async getSavedRecipes(): Promise<RecipeWithIngredientsEntity[]> {
        const recipes = await this.directus.items('saved_recipes').readMany({
            // @ts-ignore
            sort: ['-date_created'],
            fields: [
                'recipe.*',
                'recipe.tags.ingredient_id.name',
                'recipe.type.name',
                'recipe.ingredients.ingredient_id.*',
                'recipe.ingredients.ingredient_id.type.name',
                'recipe.ingredients.ingredient_id.tags.IngrfedientType_id.*',
                'recipe.optionalIngredients.ingredient_id.*',
                'recipe.optionalIngredients.ingredient_id.tags.IngredientType_id.*',
                'recipe.optionalIngredients.ingredient_id.type.name',
            ]
        });
        return recipes.data!.map(it => convertToRecipeWithIngredients((it as unknown as any).recipe));
    }

    async removeSavedRecipe(recipe: RecipeWithIngredientsEntity) {
        const recipes: any = await this.directus.items('saved_recipes').readMany({
            fields: ['id'],
            filter: {recipe: recipe.id, user: this.user.id},
        });
        await this.directus.items('saved_recipes').deleteMany(recipes.data!.map((it: any) => it.id));
    }

    private async refresh() {
        try {
            await this.directus.auth.refresh();
        } catch (e) {
            console.info('refreshing directus token failed', e);
            throw new Error('User not logged in');
        }
    }
}
