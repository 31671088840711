import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecipeFacade } from '../core/recipe.facade';
import { RecipeWithIngredientsEntity } from '../../../types';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'client-recipe-page',
    templateUrl: './recipe-page.component.html',
    styleUrls: ['./recipe-page.component.scss'],
})
export class RecipePageComponent implements OnInit {
    recipe?: RecipeWithIngredientsEntity = undefined;
    API_BASE_URL: string = environment.API_BASE_URL;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private state: RecipeFacade,
        private renderer: Renderer2,
        private title: Title,
    ) {

    }

    async ngOnInit(): Promise<void> {
        const slug: string = this.route.snapshot.params["slug"];
        try {
            this.recipe = await this.state.getRecipeBySlug(slug);
            this.createLDJson(this.recipe);
            this.title.setTitle(this.recipe.name + ' -  Wasessenwir.jetzt');
        } catch (e) {
            // todo: 404 page
            await this.router.navigateByUrl('/');
        }

    }

    private createLDJson(recipe: RecipeWithIngredientsEntity) {
        const json = {
            '@context': 'https://schema.org/',
            '@type': 'Recipe',
            'name': recipe.name,
            'image': recipe.image ? [`${environment.API_BASE_URL}/assets/${recipe.image}/${recipe.slug}.jpg'?fit=cover&&width=836&quality=80'`] : [],
            'author': {
                '@type': 'Organization',
                'name': 'Was essen wir jetzt',
            },
            'datePublished': recipe.date_created,
            'description': recipe.description || '',
            'recipeCuisine': [recipe.cuisine],
            'prepTime': recipe.prepTime,
            'cookTime': recipe.cookTime,
            'totalTime': recipe.totalTime,
            'keywords': recipe.type,
            'recipeYield': '1 serving',
            'recipeCategory': recipe.type,
            'nutrition': {
                '@type': 'NutritionInformation',
                'calories': '0 calories',
            },
            'aggregateRating': {
                '@type': 'AggregateRating',
                'ratingValue': '5',
                'ratingCount': '1',
            },
            'recipeIngredient': recipe.ingredients.map(it => it.name),
            'recipeInstructions': recipe.instructionList?.map(it => ({
                '@type': 'HowToStep',
                'text': it.instruction,
            })) || [recipe.instructions] || [recipe.description] || [],
        };

        // const safeHTML = this.getSafeHTML(json);
        this.createScriptTag(JSON.stringify(json, null, 2));
    }

    private createScriptTag(scriptContent: string) {
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = scriptContent;
        script.async = true;
        script.defer = true;
        this.renderer.appendChild(document.head, script);
    }
}
