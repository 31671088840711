import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecipeWithIngredientsEntity } from '../../../../types';
import { environment } from '../../../environments/environment';
import { UserFacade } from '../user.facade';

@Component({
    selector: 'client-recipe-card',
    templateUrl: './recipe-card.component.html',
    styleUrls: ['./recipe-card.component.scss'],
})
export class RecipeCardComponent implements OnInit {
    @Input() recipe!: RecipeWithIngredientsEntity;
    @Input() isSaved: boolean = false;
    @Input() enableSaving = false;
    @Input() linkToRoute = true;
    @Output() onSave = new EventEmitter<RecipeWithIngredientsEntity>();
    @Output() onRemove = new EventEmitter<RecipeWithIngredientsEntity>();

    API_BASE_URL: string = environment.API_BASE_URL;
    savedRecipes: any = [];
    isLoggedIn = false;

    constructor(
        private user: UserFacade,
    ) {
    }

    async ngOnInit(): Promise<void> {
        const user = await this.user.getCurrentUser();
        this.isLoggedIn = !!user;

        if (this.isLoggedIn) {
            this.savedRecipes = await this.user.getSavedRecipes();
            this.isSaved = this.user.isRecipeSaved(this.recipe);
        }
    }

    async setFavouriteRecipe(remove = false) {
        if (remove) {
            this.onRemove.emit(this.recipe);
        } else {
            this.onSave.emit(this.recipe);
        }
    }

    buildImageUrl() {
        return `${environment.API_BASE_URL}/assets/${this.recipe.image}/${this.recipe.slug}.jpg'?fit=cover&&width=836&quality=80`;
    }
}
