<ion-toolbar color="primary">
    <ion-buttons slot="secondary">
        <ion-button routerLink="/suche">
            <ion-icon slot="icon-only" name="search"></ion-icon>
        </ion-button>
        <!--            <ion-button>-->
        <!--                <ion-icon slot="icon-only" name="person-circle"></ion-icon>-->
        <!--            </ion-button>-->
    </ion-buttons>
    <!--        <ion-buttons slot="primary">-->
    <!--            <ion-button>-->
    <!--                <ion-icon slot="icon-only" ios="ellipsis-horizontal" md="ellipsis-vertical"></ion-icon>-->
    <!--            </ion-button>-->
    <!--        </ion-buttons>-->
    <a routerLink="/zufallsgenerator" class="nav-link">
        <ion-title>
            <img src="/assets/logo.png" alt="Logo von wasessenwir.jetzt" width="48" height="48"
                    style="margin-right: 4px">
            <b>Was essen wir jetzt?</b>
        </ion-title>
    </a>
</ion-toolbar>
