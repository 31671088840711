<ion-content>
    <div class="container">
        <h1>Gespeicherte Rezepte</h1>
        <client-recipe-card *ngFor="let recipe of savedRecipes"
                [recipe]="recipe"
                [isSaved]="true"
                [enableSaving]="true"
                (onRemove)="removeRecipe($event)"
        ></client-recipe-card>
        <p *ngIf="isLoggedIn && !savedRecipes.length">Speichere Rezepte mit dem
            <mat-icon>favorite</mat-icon>
            -Symbol
        </p>
        <p *ngIf="!isLoggedIn">
            <a *ngIf="!isLoggedIn"
                    href="https://wasessenwir.jetzt/directus/auth/login/google?redirect=https://wasessenwir.jetzt">
                Mit Google anmelden
            </a>&nbsp;

        </p>
    </div>

    <app-footer></app-footer>

</ion-content>
