<ion-content>
    <div class="AboutUs container" data-testid="Imprint">
        <div class="content text-center">
            <h1>Impressum</h1>
            <img src="assets/imprint.png"/>
            <p>Quelle: <a href="https://www.e-recht24.de" target="_blank">eRecht24</a></p>


        </div>
    </div>

    <app-footer></app-footer>
</ion-content>
