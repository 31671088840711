import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RECIPE_TYPES, RecipeWithIngredientsEntity, RecipeWithScore } from '../../../types';
import { RecipeService } from '../core/recipe.service';
import { RecipeFacade } from '../core/recipe.facade';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from '../core/google-analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { UserFacade } from '../core/user.facade';

@Component({
    selector: 'client-discover',
    templateUrl: './discover-recipes.component.html',
    styleUrls: ['./discover-recipes.component.scss'],
})
export class DiscoverRecipesComponent implements OnInit {
    RECIPE_TYPES: typeof RECIPE_TYPES = RECIPE_TYPES;
    matchingRecipes: RecipeWithScore[] = [];
    matchingRecipe?: RecipeWithScore;
    isLoggedIn = false;
    private savedRecipes: RecipeWithScore[] = [];
    isRecipeSaved = false;

    constructor(
        private recipe: RecipeService,
        private state: RecipeFacade,
        private user: UserFacade,
        private route: ActivatedRoute,
        private ga: GoogleAnalyticsService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {

    }

    async ngOnInit(): Promise<void> {
        this.state.resetMatchingRecipes();
        this.state.select(it => it.matchingRecipes).subscribe(it => this.matchingRecipes = it);

        const user = await this.user.getCurrentUser(false);
        this.isLoggedIn = user;
    }

    async doShuffleRecipes(type?: RECIPE_TYPES, onlyRecipesWithImages: boolean = false) {
        if (isPlatformBrowser(this.platformId)) {
            this.ga.trackEvent('shuffle_recipes', {page_location: window.location.pathname, type: type});
        }
        this.matchingRecipes = await this.state.shuffleRecipes(type, onlyRecipesWithImages);
        this.matchingRecipe = this.matchingRecipes.slice(0, 1)[0];
        this.isRecipeSaved = await this.checkIsRecipeSaved();
    }

    async saveRecipe(recipe: RecipeWithIngredientsEntity) {
        await this.user.saveRecipe(recipe);
        this.isRecipeSaved = await this.checkIsRecipeSaved();
    }

    async removeRecipe(recipe: RecipeWithIngredientsEntity) {
        await this.user.removeSavedRecipe(recipe);
        this.isRecipeSaved = await this.checkIsRecipeSaved();
    }

    async checkIsRecipeSaved() {
        if (this.isLoggedIn) {
            this.savedRecipes = await this.user.getSavedRecipes();

            const found = this.savedRecipes.find(it => it.id === this.matchingRecipe?.id);
            return !!found;
        } else {
            return false;
        }
    }
}
