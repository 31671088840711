import { Component } from '@angular/core';
import { SearchRecipesComponent } from '../../search-recipes/search-recipes.component';

@Component({
    selector: 'client-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    search = SearchRecipesComponent;
    root: any;
}
