import { Component, OnInit } from '@angular/core';
import { RecipeService } from '../core/recipe.service';
import { RecipeWithScore } from '../../../types';
import { RecipeFacade } from '../core/recipe.facade';
import { GoogleAnalyticsService } from '../core/google-analytics.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'client-search-recipes',
    templateUrl: './search-recipes.component.html',
    styleUrls: ['./search-recipes.component.scss'],
})
export class SearchRecipesComponent implements OnInit {
    recipes: RecipeWithScore[] = [];
    foundRecipes: RecipeWithScore[] = [];
    searchTerm: string = '';

    constructor(
        private recipe: RecipeService,
        private state: RecipeFacade,
        private ga: GoogleAnalyticsService,
        private route: ActivatedRoute
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.recipes = await this.state.getAllRecipes();
        const params = this.route.snapshot.params;
        console.log(params);

        if(params['searchTerm']) {
            this.searchTerm = params['searchTerm'];
            this.doSearch(params['searchTerm']);
        }
    }

    doSearch(searchTerm: string) {
        this.ga.trackEvent('search_recipes', {page_location: window.location.pathname, searchTerm: searchTerm});
        this.foundRecipes = this.recipes.filter(it => it.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }

}
