<ion-content>
    <div class="container">
        <form class="example-form">
            <div class="row">
                <div class="col">
                    <mat-form-field class="example-full-width">
                        <mat-label>Rezepte suchen</mat-label>
                        <input matInput type="text" [(ngModel)]="searchTerm" name="searchTerm">
                        <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Zurücksetzen" type="button"
                                (click)="searchTerm=''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-auto">
                    <button mat-raised-button color="primary" type="submit" (click)="doSearch(searchTerm)">Suchen
                    </button>
                </div>
            </div>
        </form>

        <client-recipe-card *ngFor="let recipe of foundRecipes" [recipe]="recipe"></client-recipe-card>
    </div>

    <app-footer></app-footer>

</ion-content>
