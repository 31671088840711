<ion-content>
    <div class="Discover">
        <div class="container">
            <div class="hero" *ngIf="!matchingRecipe">
                <h1 class="text-center display-6">Keine Idee, was du heute leckeres kochen könntest?</h1>
                <p class="text-center">Finde neue Rezepte mit unserem Zufallsgenerator oder <a
                        routerLink="/zutatensuche">suche
                    einfach nach Zutaten </a>die du
                    gerade Zuhause hast.</p>
            </div>
            <div class="center separator">
                <button mat-raised-button color="primary" (click)="doShuffleRecipes()">
                    Zufälliges Rezept finden
                </button>

                <p class="text-center">Ich will aber lieber...</p>
                <div>
                    <button mat-stroked-button size="small" (click)=doShuffleRecipes(RECIPE_TYPES.SALAD)>
                        Salat
                    </button>
                    <button mat-stroked-button size="small" (click)=doShuffleRecipes(RECIPE_TYPES.SOUP)>
                        Suppe
                    </button>
                    <button mat-stroked-button size="small" (click)=doShuffleRecipes(RECIPE_TYPES.PASTA)>
                        Pasta
                    </button>
                </div>
            </div>
            <div *ngIf="matchingRecipe">
                <client-recipe-card [recipe]="matchingRecipe"
                        [isSaved]="isRecipeSaved"
                        [enableSaving]="true"
                        (onRemove)="removeRecipe($event)"
                        (onSave)="saveRecipe($event)"
                ></client-recipe-card>
            </div>
        </div>
    </div>

    <app-footer></app-footer>

</ion-content>
