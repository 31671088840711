import { Component, OnInit } from '@angular/core';
import { UserFacade } from '../core/user.facade';
import { RecipeWithIngredientsEntity } from '../../../types';

@Component({
    selector: 'client-saved-recipes',
    templateUrl: './saved-recipes.component.html',
    styleUrls: ['./saved-recipes.component.scss'],
})
export class SavedRecipesComponent implements OnInit  {
    savedRecipes: RecipeWithIngredientsEntity[] = [];
    isLoggedIn = false;

    constructor(
        private user: UserFacade,
    ) {
    }

    async ngOnInit(): Promise<void> {
    }

    async ionViewDidEnter(){
        const user = await this.user.getCurrentUser();
        this.isLoggedIn = !!user;
        this.savedRecipes = await this.user.getSavedRecipes();
    }

    async removeRecipe(recipe: RecipeWithIngredientsEntity) {
        this.savedRecipes = await this.user.removeSavedRecipe(recipe);
    }
}
