<script src="../recipe-badges/recipe-badges.component.spec.ts"></script>
<div *ngIf="recipe" class="RecipeCard text-left">
    <a *ngIf="linkToRoute" [routerLink]="recipe.slug ? '/rezepte/' + recipe.slug: null"
            class="text-decoration-none text-reset">
        <h3 class="ion-margin-top">{{recipe.name}}</h3>
        <h4 *ngIf="recipe.subtitle" class="uppercase">{{recipe.subtitle}}</h4>
    </a>
    <h3 class="ion-margin-top title" *ngIf="!linkToRoute">{{recipe.name}}</h3>

    <div class="row" *ngIf="recipe.image; else noImage">
        <div class="col image-crop">
            <img [src]="buildImageUrl()" [alt]="recipe.name" class="img-fluid">
        </div>
    </div>

    <ng-template #noImage>
        <div class="missing-info">
            <p><i><small>Für dieses Gericht haben wir leider noch kein Bild</small></i></p>
        </div>
    </ng-template>


    <ng-container *ngIf="enableSaving">
        <div class="d-flex justify-content-end" *ngIf="isLoggedIn; else isLoggedOut">
            <button mat-button *ngIf="!isSaved" (click)="setFavouriteRecipe()">
                <mat-icon>favorite</mat-icon>
                Speichern

            </button>
            <button mat-button *ngIf="isSaved" (click)="setFavouriteRecipe(true)">
                <mat-icon>delete</mat-icon>
                Entfernen
            </button>
        </div>

        <ng-template #isLoggedOut>
            <div class="d-flex justify-content-end">
                <a mat-button
                        href="https://wasessenwir.jetzt/directus/auth/login/google?redirect=https://wasessenwir.jetzt/login">
                    <mat-icon>favorite</mat-icon>
                    Speichern
                </a>
            </div>
        </ng-template>
    </ng-container>

    <client-recipe-badges
            [time]="recipe.totalTime"
            [manyGuests]="recipe.manyGuests!"
            [vegetarian]="recipe.vegetarian!"
            [vegan]="recipe.vegan!">
    </client-recipe-badges>

    <p class="description">{{recipe.description}}</p>

    <div class="row margin" *ngIf="recipe.ingredients.length > 0">
        <div class="col">
            <h4 class="uppercase">Zutaten</h4>
            <ul>
                <li *ngFor="let it of recipe.ingredients" class="text-left">{{it.name}}</li>
            </ul>
        </div>
        <div class="col" *ngIf="recipe.optionalIngredients.length > 0">
            <h4 class="uppercase">Optional</h4>
            <ul class="text-left">
                <li *ngFor="let it of recipe.optionalIngredients">{{it.name}}</li>
            </ul>
        </div>
    </div>

    <div class="margin" *ngIf="recipe.instructions || recipe.instructionList">
        <h4 class="uppercase">Zubereitung</h4>
        <p class="description" *ngIf="recipe.instructions && !recipe.instructionList">{{recipe.instructions}}</p>
        <ol *ngIf="recipe.instructionList">
            <li *ngFor="let instruction of recipe.instructionList">{{instruction.instruction}}</li>
        </ol>
    </div>

    <div class="missing-info" *ngIf="!recipe.instructions && !recipe.instructionList">
        <p><mat-icon [fontIcon]="'lightbulb'"></mat-icon></p>
        <p><i><small>Für dieses Gericht haben wir leider noch kein Rezept</small></i></p>
        <p><i><small>Lass dich inspirieren!</small></i></p>
    </div>

</div>
