import { Component, OnInit } from '@angular/core';
import { IngredientEntity, RecipeWithScore } from '../../../types';
import { RecipeService } from '../core/recipe.service';
import { RecipeFacade } from '../core/recipe.facade';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'client-discover-by-ingredient',
    templateUrl: './discover-by-ingredient.component.html',
    styleUrls: ['./discover-by-ingredient.component.scss'],
})
export class DiscoverByIngredientComponent implements OnInit {
    recipes: RecipeWithScore[] = [];

    constructor(
        private recipe: RecipeService,
        private state: RecipeFacade,
        private route: ActivatedRoute,
    ) {
    }

    async ngOnInit(): Promise<void> {
        const ingredient = this.route.snapshot.params['ingredient'].toLowerCase();
        this.recipes = await this.state.findMatchingRecipe([{name: ingredient} as IngredientEntity]);
    }

}
