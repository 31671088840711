import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
// @ts-ignore
import attachBanner from '@beyonk/gdpr-cookie-consent-banner/dist/esm/bundle.js';
import { environment } from '../environments/environment';
import { VERSION } from '../environments/version';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from './core/google-analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { RecipeWithIngredientsEntity } from '../../types';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    environment = environment;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private renderer: Renderer2,
        private router: Router,
        private ga: GoogleAnalyticsService,
    ) {
        console.log(`Application version is: ${VERSION.version}, SHA: ${VERSION.hash}`);

        this.router.events.subscribe((event) => {
            if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd) {
                this.ga.trackPageView(event.urlAfterRedirects, document.title);
            }
        });
    }

    async ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            attachBanner(document.body, this.gdprOptions);
        }

        this.createLDJson();
    }

    private createLDJson() {
        const breadcrumbList = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
                {
                    '@type': 'ListItem',
                    'position': 1,
                    'name': 'Rezepte Zufallsgenerator ',
                    'item': 'https://wasessenwir.jetzt/zufallsgenerator',
                },
                {
                    '@type': 'ListItem',
                    'position': 2,
                    'name': 'Rezeptsuche nach Zutaten',
                    'item': 'https://wasessenwir.jetzt/zutatensuche',
                },
                {
                    '@type': 'ListItem',
                    'position': 3,
                    'name': 'Suppen',
                    'item': 'https://wasessenwir.jetzt/kategorie/soup',
                },
                {
                    '@type': 'ListItem',
                    'position': 4,
                    'name': 'Pasta',
                    'item': 'https://wasessenwir.jetzt/kategorie/pasta',
                },
                {
                    '@type': 'ListItem',
                    'position': 5,
                    'name': 'Salate',
                    'item': 'https://wasessenwir.jetzt/kategorie/salad',
                },
            ],
        };


        const searchAction = {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            'name': 'Wasessenwir.jetzt',
            'url': 'https://wasessenwir.jetzt/',
            'potentialAction': {
                '@type': 'SearchAction',
                'target': 'https://wasessenwir.jetzt/suche/{search_term}',
                'query-input': 'required name=search_term',
            },
        };

        // const safeHTML = this.getSafeHTML(json);
        this.createLDJsonScriptTag(JSON.stringify(breadcrumbList, null, 2));
        this.createLDJsonScriptTag(JSON.stringify(searchAction, null, 2));
    }

    private createLDJsonScriptTag(scriptContent: string) {
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = scriptContent;
        script.async = true;
        script.defer = true;
        this.renderer.appendChild(document.head, script);
    }

    createScriptTag(scriptContent: string) {
        const script = document.createElement('script');
        script.text = scriptContent;
        this.renderer.appendChild(document.head, script);
    }

    createScriptFromSrc(src: string) {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        this.renderer.appendChild(document.head, script);
    }

    gdprOptions = {
        /**
         * You must set the cookie name.
         **/
        cookieName: 'wasessenwirjetzt-consent',

        /**
         * The cookie configuration, such as domain and path.
         **/
        cookieConfig: {
            domain: environment.production ? 'wasessenwir.jetzt' : 'localhost',
            path: '/',
        },

        /**
         * These are the top two lines of text on the banner
         * The 'description' field can include html such as links
         **/
        heading: 'Verwendung von Cookies',
        description:
            'Wir verwenden Cookies, um eine bessere Browser-Erfahrung zu bieten, den Traffic der Website zu analysieren, Inhalte zu personalisieren und gezielte Werbung anzuzeigen. Bitte lesen Sie unsere <a href="/datenschutz">Datenschutzrichtlinie</a>. Durch Klicken auf "Akzeptieren" erklären Sie sich mit unserer Datenschutzrichtlinie und der Verwendung von Cookies einverstanden.',

        /**
         * All the button labels and aria-label content.
         **/
        acceptLabel: 'Alle akzeptieren',
        rejectLabel: 'Alle ablehnen',
        settingsLabel: 'Einstellungen',
        closeLabel: 'Fenster schließen',
        editLabel: 'Einstellungen bearbeiten',

        /**
         * These are the default opt-ins and their descriptions.
         * When value is set to true, the option will automatically be checked on load.
         *
         * If you want to hide a category, simply set it to false, e.g. 'marketing: false'
         **/
        choices: {
            necessary: {
                label: 'Notwendige Cookies',
                description: 'Wird benötigt, um die erlaubten Cookies zu speichern. Kann nicht abgeschaltet werden.',
                value: true,
            },
            tracking: false,
            marketing: false,
            analytics: {
                label: 'Analytics Cookies',
                description:
                    'Wird von Google Analytics verwendet, einem von Google angebotenen 3rd-Party-Tool zur Verfolgung von Benutzerverhalten.',
                value: true,
            },
        },

        /**
         * Show an icon to edit cookies later, when banner is closed.
         **/
        showEditIcon: true,

        /**
         * These are the functions which are run if a user opts-in to that category.
         * You should drop your cookies here (or set a variable to control the later dropping of cookies.
         *
         * If you are using svelte, you can use events instead - see the Svelte section below.
         **/
        categories: {
            analytics: () => {
                console.log('granted');
                this.ga.enableTracking();
                this.createScriptFromSrc('https://www.googletagmanager.com/gtag/js?id=' + environment.GA4_PROPERTY);
                this.createScriptTag(this.gaConfig);
            },
            // tracking: function () {
            //     console.log("No tracking cookies specified");
            // },
            // marketing: function () {
            //
            //     console.log("No marketing cookies specified");
            // },
            necessary: function () {
            },
        },
    };

    gaConfig = `window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('js', new Date());

        gtag("consent", "default", {
              analytics_storage: 'granted',
              ad_storage: 'granted',
              functionality_storage: 'granted',
              personalization_storage: 'granted',
              security_storage: 'granted',
        });
        gtag("set", "ads_data_redaction", true);

        gtag('config', '${environment.GA4_PROPERTY}', {
            'send_page_view': false,
        });
        `;
}
