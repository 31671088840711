<div class="Info">
    <div class="container text-center" style="max-width: 520px; margin-left: auto; margin-right: auto">
        <img class="img-fluid margin" src="assets/images/strawberry-bowl.png"
                alt="eine farbenfrohe, blaue Schüssel mit Erdbeeren und  Joghurt"/>
        <p class="header"><b>
            Auf wasessenwir.jetzt findest du leckere und einfache Rezepte, die sich
            schnell und ohne großen Aufwand zubereiten lassen.
        </b></p>
        <p>Manchmal braucht man einfach eine kleine Idee oder Inspiration, um eine leckeres Rezept zu
            finden.
            Über
            unsere Zufallssuche lassen sich einfach neue Rezepte entdecken.</p>

        <p>Wir konzentrieren uns besonders auf gesunde Rezepte, die man gut im Alltag zubereiten kann,
            wenn
            abends
            nach der Arbeit oder während der Mittagspause nicht viel Zeit zum Kochen bleibt.
            Auf unserer Seite finden sich daher nur unkomplizierte Rezepte, die in maximal 30 Minuten
            zubereitet
            werden können.
            Besonders mögen wir leichte Suppen oder Salate, da sie kalorienarm sind und aus vielen
            frischen
            und
            gesunden Zutaten bestehen.
        </p>

        <p>Für alle, die eine bestimmte Zutat im Kühlschrank haben und nicht wissen, was sie damit
            machen
            sollen,
            bieten wir auch die Möglichkeit, nach
            passenden Rezepten zu suchen. So kann man sozusagen auch ein leckeres Essen aus Nichts
            zaubern.</p>
    </div>
</div>

<footer class="Footer">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <h6>Lass dich inspirieren</h6>
                <ul class="list-unstyled">
                    <li><a routerLink="/kategorie/soup">Suppen</a></li>
                    <li><a routerLink="/kategorie/pasta">Pasta</a></li>
                    <li><a routerLink="/kategorie/salad">Salate</a></li>
                    <li><a routerLink="/kategorie/dish">Klassische Rezepte</a></li>
                    <li><a routerLink="/kategorie/baked_dish">Rezepte für den Ofen</a></li>
                </ul>
            </div>
            <div class="col-6">
                <h6>Rezepte nach Zutaten</h6>
                <ul class="list-unstyled">
                    <li><a routerLink="/rezepte-mit/maultaschen">Maultaschen</a></li>
                    <li><a routerLink="/rezepte-mit/kartoffeln">Kartoffeln</a></li>
                    <li><a routerLink="/rezepte-mit/käse">Käse</a></li>
                    <li><a routerLink="/rezepte-mit/kokosmilch">Kokosmilch</a></li>
                </ul>
            </div>
        </div>
        <div class="legal">
            <a *ngIf="environment.production && !isLoggedIn"
                    href="https://wasessenwir.jetzt/directus/auth/login/google?redirect=https://wasessenwir.jetzt/login">Google
                Login
            </a>&nbsp;
            <a *ngIf="!environment.production && !isLoggedIn"
                    href="https://wasessenwir.jetzt/directus/auth/login/google?redirect=http://localhost:4200/login">Google
                Login
            </a>
            <p *ngIf="isLoggedIn">Angemeldet als {{userData.email}}</p>&nbsp;
        </div>
        <div class="legal">
            <a routerLink="/ueber-uns">Über Uns</a>&nbsp;
            <a routerLink="/impressum">Impressum</a>&nbsp;
            <a routerLink="/datenschutz">Datenschutzerklärung</a>&nbsp;
            <a href="https://play.google.com/store/apps/details?id=jetzt.wasessenwir.app"
                    target="_blank">
                Was essen wir jetzt im Google Play-Store
            </a>
        </div>
    </div>
</footer>
