import { Component, OnInit } from '@angular/core';
import { UserFacade } from '../user.facade';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    environment: typeof environment;
    isLoggedIn: boolean = false;
    private userData: any;


    constructor(
        private user: UserFacade,
    ) {
        this.environment = environment;
    }

    async ngOnInit(): Promise<void> {
        this.userData = await this.user.getCurrentUser(true);
        this.isLoggedIn = !!this.userData;
    }
}
