import { Injectable } from '@angular/core';
import { Commands, Facade } from '@w11k/tydux';
import { IngredientEntity, RECIPE_TYPES, RecipeEntity, RecipeWithScore } from '../../../types';
import { RecipeService } from './recipe.service';

export const createRecipeState = () => ({
    allRecipes: [] as RecipeWithScore[],
    matchingRecipes: [] as RecipeWithScore[],
    allIngredients: [] as IngredientEntity[],
    selectedIngredients: [] as IngredientEntity[],
});

export type RecipeState = ReturnType<typeof createRecipeState>;

class RecipeCommands extends Commands<RecipeState> {

    setAllRecipes(recipes: RecipeWithScore[]) {
        this.state = {...this.state, allRecipes: recipes};
    }

    setMatchingRecipes(recipes: RecipeWithScore[]) {
        this.state = {...this.state, matchingRecipes: recipes};
    }

    resetMatchingRecipes() {
        this.state.matchingRecipes = [];
    }

    setAllIngredients(ingredients: IngredientEntity[]) {
        this.state = {...this.state, allIngredients: ingredients};
    }

    addSelectedIngredient(ingredient: IngredientEntity) {
        this.state = {...this.state, selectedIngredients: this.state.selectedIngredients.concat(ingredient)};
    }

    removeSelectedIngredient(ingredient: IngredientEntity) {
        this.state = {
            ...this.state,
            selectedIngredients: this.state.selectedIngredients.filter(c => c.id !== ingredient.id),
        };
    }
}

@Injectable({
    providedIn: 'root',
})
export class RecipeFacade extends Facade<RecipeCommands> {
    constructor(
        private readonly recipe: RecipeService,
    ) {
        super('recipes', new RecipeCommands(), createRecipeState());
    }

    async getAllRecipes(type?: RECIPE_TYPES) {
        let recipes = this.state.allRecipes;
        // todo chaching
        // if (recipes.length === 0) {
            recipes = await this.recipe.getAllRecipes(type);
            this.setAllRecipes(recipes);
        // }
        return recipes;
    }

    setAllRecipes(recipes: RecipeWithScore[]) {
        this.commands.setAllRecipes(recipes);
    }

    setMatchingRecipes(recipes: RecipeWithScore[]) {
        this.commands.setMatchingRecipes(recipes);
    }

    setAllIngredients(ingredients: IngredientEntity[]) {
        this.commands.setAllIngredients(ingredients);
    }

    async addSelectedIngredient(ingredient: IngredientEntity) {
        this.commands.addSelectedIngredient(ingredient);
        await this.findMatchingRecipe(this.state.selectedIngredients)
    }

    async removeSelectedIngredient(ingredient: IngredientEntity) {
        this.commands.removeSelectedIngredient(ingredient);
        await this.findMatchingRecipe(this.state.selectedIngredients)
    }

    isIngredientSelected(ingredient: IngredientEntity) {
        console.log("isIngredientSelected", ingredient.name, this.state.selectedIngredients.includes(ingredient));
        // return !!this.state.selectedIngredients.find(it => it.id === ingredient.id);
        return this.state.selectedIngredients.includes(ingredient);
    }

    async findMatchingRecipe(selectedIngredients: IngredientEntity[]) {
        const recipes = await this.getAllRecipes();
        const matchingRecipes = await this.recipe.getRecipeForIngredients(recipes, selectedIngredients);
        this.setMatchingRecipes(matchingRecipes);
        return matchingRecipes;
    }

    async getAllIngredients() {
        if (this.state.allIngredients.length ===0) {
            const allIngredients = await this.recipe.getAllIngredients();
            this.setAllIngredients(allIngredients);
        }
        return this.state.allIngredients;
    }

    async shuffleRecipes(type?: RECIPE_TYPES, onlyRecipesWithImages: boolean = false) {
        let recipes = await this.getAllRecipes(type);
        if (onlyRecipesWithImages) {
            recipes = recipes.filter(it => !!it.image)
        }
        return this.shuffle<RecipeWithScore>(recipes.slice());
    };

    private shuffle<T>(a: T[]): T[] {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    };

    resetMatchingRecipes() {
        this.commands.resetMatchingRecipes();
    }

    async getRecipeBySlug(slug: string) {
        return await this.recipe.getRecipeBySlug(slug)
    }
}
