<ion-app>
    <ion-tabs>
        <ion-header>
            <client-header></client-header>
        </ion-header>

        <ion-tab-bar slot="bottom" selected-tab="zufallsgenerator" color="primary">
            <ion-tab-button tab="zufallsgenerator">
                <ion-icon name="play-circle"></ion-icon>
                Zufällige Rezepte
            </ion-tab-button>
            <ion-tab-button tab="zutatensuche">
                <ion-icon name="radio"></ion-icon>
                Zutatensuche
            </ion-tab-button>
            <ion-tab-button tab="gespeicherte-rezepte">
                <mat-icon>favorite</mat-icon>
                Gespeicherte Rezepte
            </ion-tab-button>
            <ion-tab-button tab="suche">
                <ion-icon name="search"></ion-icon>
                Suche
            </ion-tab-button>
        </ion-tab-bar>
    </ion-tabs>
</ion-app>

