import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'client-aboutus',
    templateUrl: './aboutus.component.html',
    styleUrls: ['./aboutus.component.scss'],
})
export class AboutUsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
