import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserFacade } from '../core/user.facade';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    constructor(
        private router: Router,
        private user: UserFacade,
    ) {
    }

    async ngOnInit(): Promise<void> {
        const user$ = await this.user.getCurrentUser(true);
        await this.router.navigateByUrl('/');
    }
}
