import { Injectable } from '@angular/core';
import {
    DirectusSchema,
    INGREDIENT_TYPES,
    IngredientDTO,
    IngredientEntity,
    IngredientWithMappedRecipesEntity,
    RECIPE_TYPES,
    RecipeDTO,
    RecipeEntity,
    RecipeIngredientMapping,
    RecipeWithIngredientsEntity,
    RecipeWithScore,
} from '../../../types';
import { environment } from '../../environments/environment';
import { Directus, ManyItems, PartialItem } from '@directus/sdk';

@Injectable({
    providedIn: 'root',
})
export class RecipeService {

    getDirectusHandler = () => new Directus<DirectusSchema>(environment.API_BASE_URL);

    constructor() {
    }

    convertToIngredientEntityWithMappedRecipes =
        (it: PartialItem<IngredientDTO>, recipeMappings: PartialItem<RecipeIngredientMapping>[]): IngredientWithMappedRecipesEntity => {
            return ({
                id: it.id!,
                name: it.name!,
                description: it.description,
                vegetarian: it.vegetarian,
                vegan: it.vegan,
                type: it.type!.name as INGREDIENT_TYPES,
                tags: it.tags!.map(it => it.IngredientType_id.name) as INGREDIENT_TYPES[],
                recipes: recipeMappings.filter(mapping => mapping.ingredient_id!.id === it.id).map(it => it.recipe_id!).map(it => this.convertToRecipeEntity(it!)),
            });
        };

    convertToRecipeEntity = (it: PartialItem<RecipeDTO>): RecipeEntity => {
        return ({
            id: it.id!,
            name: it.name!,
            slug: it.slug,
            cuisine: it.cuisine,
            description: it.description,
            instructions: it.instructions,
            image: it.image,
            recipeUrl: it.recipeUrl,
            cookTime: it.cookTime,
            prepTime: it.prepTime,
            totalTime: it.totalTime,
            vegetarian: it.vegetarian,
            vegan: it.vegan,
            manyGuests: it.manyGuests,
            type: it.type?.name as RECIPE_TYPES,
        });
    };

    async getAllIngredients() {
        const directus = this.getDirectusHandler();
        const ingredientDtos: ManyItems<IngredientDTO> = await directus.items('ingredient')
            .readMany({fields: ['*', 'tags.IngredientType_id.name', 'type.name']});

        const recipeMappings: ManyItems<RecipeIngredientMapping> = await directus.items('recipe_ingredient')
            .readMany({fields: ['ingredient_id.id', 'recipe_id.*']});

        const ingredients = ingredientDtos.data!.map(it => this.convertToIngredientEntityWithMappedRecipes(it, recipeMappings.data!));

        return ingredients.sort((a, b) => b.recipes.length - a.recipes.length);
    }

    async getAllRecipes(type?: RECIPE_TYPES): Promise<RecipeWithScore[]> {
        const directus = this.getDirectusHandler();

        const recipeDtos: ManyItems<RecipeDTO> = await directus.items('recipe')
            .readMany({
                fields: [
                    '*',
                    'tags.ingredient_id.name',
                    'type.name',
                    'ingredients.ingredient_id.*',
                    'ingredients.ingredient_id.type.name',
                    'ingredients.ingredient_id.tags.IngredientType_id.*',
                    'optionalIngredients.ingredient_id.*',
                    'optionalIngredients.ingredient_id.tags.IngredientType_id.*',
                    'optionalIngredients.ingredient_id.type.name',
                    'saved_recipes.*'
                ],
                filter: {type: type ? {'name': {'_eq': type}} : undefined},
            });

        const recipes = recipeDtos.data!.map(it => convertToRecipeWithIngredients(it));
        // console.log('getAllRecipes', recipes);
        return recipes as RecipeWithScore[];
    }

    async getRecipeForIngredients(recipes: RecipeWithScore[], selectedIngredients: IngredientEntity[]): Promise<RecipeWithScore[]> {
        const matchingRecipes: RecipeWithScore[] = this.findMatchingRecipes(recipes, selectedIngredients);
        return matchingRecipes.filter(it => it.score! > 0);
    }

    async getRecipeBySlug(slug: string) {
        const directus = this.getDirectusHandler();

        const recipeDtos: ManyItems<RecipeDTO> = await directus.items('recipe')
            .readMany({
                fields: [
                    '*',
                    'tags.ingredient_id.name',
                    'type.name',
                    'ingredients.ingredient_id.*',
                    'ingredients.ingredient_id.type.name',
                    'ingredients.ingredient_id.tags.IngredientType_id.*',
                    'optionalIngredients.ingredient_id.*',
                    'optionalIngredients.ingredient_id.tags.IngredientType_id.*',
                    'optionalIngredients.ingredient_id.type.name',
                ],
                filter: {slug: slug},
            });

        console.log('getRecipeBySlug', recipeDtos);
        return convertToRecipeWithIngredients(recipeDtos.data![0]) as RecipeWithIngredientsEntity;
    }

    private findMatchingRecipes(recipes: RecipeWithScore[], ingredients: IngredientEntity[]) {
        const scoredRecipes: RecipeWithScore[] = recipes.map((recipe) => {
            const booleans = ingredients.map(ingredient => recipe.ingredients!.map(it => it?.name.toLowerCase())?.includes(ingredient.name.toLowerCase()));
            return {...recipe, score: booleans.filter(it => it === true).length};
        });

        return scoredRecipes.sort((a, b) => b.score! - a.score!);
    };

    getRandomInt = (min: number = 0, max: number = 100) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    };
}

export const convertToRecipeWithIngredients = (it: PartialItem<RecipeDTO>): RecipeWithIngredientsEntity =>
    ({
        id: it.id!,
        name: it.name!,
        slug: it.slug,
        cuisine: it.cuisine,
        description: it.description,
        instructions: it.instructions,
        instructionList: it.instructionList,
        image: it.image,
        recipeUrl: it.recipeUrl,
        cookTime: it.cookTime,
        prepTime: it.prepTime,
        totalTime: it.totalTime,
        vegetarian: it.vegetarian,
        vegan: it.vegan,
        manyGuests: it.manyGuests,
        type: it.type?.name as RECIPE_TYPES,
        ingredients: it.ingredients!.map(it => convertToIngredientEntity(it!.ingredient_id!)),
        optionalIngredients: it.optionalIngredients!.map(it => convertToIngredientEntity(it!.ingredient_id!)),
    });

export const convertToIngredientEntity = (it: PartialItem<IngredientDTO>): IngredientEntity => {
    return ({
        id: it.id!,
        name: it.name!,
        description: it.description,
        vegetarian: it.vegetarian,
        vegan: it.vegan,
        type: it.type!.name as INGREDIENT_TYPES,
        tags: it.tags!.map(it => it.IngredientType_id.name) as INGREDIENT_TYPES[],
    });
};
