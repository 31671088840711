import { NgModule } from '@angular/core';
import { RecipeCardComponent } from './recipe-card/recipe-card.component';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { RecipeBadgesComponent } from './recipe-badges/recipe-badges.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { IonicModule } from '@ionic/angular';


@NgModule({
    declarations: [
        RecipeCardComponent,
        RecipeBadgesComponent,
        HeaderComponent,
        FooterComponent,
    ],
    imports: [
        CommonModule,
        RouterLink,
        MatIconModule,
        MatButtonModule,
        IonicModule,
    ],
    exports: [
        RecipeCardComponent,
        RecipeBadgesComponent,
        HeaderComponent,
        FooterComponent,
    ],
})
export class CoreModule {
}
