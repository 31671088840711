<ion-content>
    <div className="AboutUs container" data-testid="Imprint">
        <div className="content text-center">
            <h1>Über Uns</h1>
            <p><i>Ich habe Hunger, was soll ich essen? Willkommen auf wasessenwir.jetzt!</i></p>
            <p><b>
                Unser Ziel ist es, Menschen dabei zu unterstützen, leckere und einfache Rezepte zu finden, die sich
                schnell und ohne großen Aufwand zubereiten lassen.
            </b></p>
            <p>
                Wir wissen, dass es manchmal schwierig sein kann, Zeit und Inspiration zu finden, um etwas Leckeres zu
                kochen. Deshalb bieten wir auf unserer Seite zufällig ausgewählte Rezepte aus verschiedenen Kategorien
                an, die in maximal 30 Minuten zubereitet werden können. Für alle, die eine bestimmte Zutat im
                Kühlschrank haben und nicht wissen, was sie damit machen sollen, bieten wir auch die Möglichkeit, nach
                passenden Rezepten zu suchen. So kann man sozusagen auch ein leckeres Essen aus Nichts zaubern.
            </p>
            <p>
                Unser Fokus liegt darauf, dass auch Menschen im Home-Office oder mit wenig Zeit leckere und schnell
                zuzubereitende Rezepte finden können. In der Mittagspause hat man meistens wenig Zeit zum kochen und
                möchte daher lieber ein unkompliziertes Rezept zubereiten. Auch möchte man im Alltag lieber ein Gericht
                essen, das nicht viele unnötige Kalorien hat. Außerdem sind solche schnellen Rezepte nicht nur
                kaloriensparend, sondern auch günstiger, weil man weniger Zutaten kaufen muss und diese wenig Geld
                kosten.
            </p>
            <p>
                Auf unserer Seite findest du zufällig ausgewählte Rezepte aus verschiedenen Kategorien, von veganen
                Gerichten bis hin zu klassischen Fleischgerichten. Du kannst auch unsere Zutatensuche nutzen, um
                passende Rezepte für deine Lieblingszutaten zu finden.
            </p>
            <p>
                Wir hoffen, dass unsere Seite dir bei der Suche nach neuen Ideen für deine Küche hilft und wir freuen
                uns, von dir zu hören! Wenn du Fragen oder Anregungen hast, zögere nicht, uns zu kontaktieren.
            </p>
        </div>
    </div>
</ion-content>
