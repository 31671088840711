import { Injectable } from '@angular/core';

export declare const gtag: Function;

declare global {
    interface Window {
        gtag: any;
    }
}

@Injectable({
    providedIn: 'root',
})

export class GoogleAnalyticsService {
    private trackingEnabled = false;

    constructor() {
    }

    trackPageView(url: string, title: string) {
        if (this.trackingEnabled) {
            gtag('event', 'page_view', {
                'page_location': url,
                'page_title': title,
            });
        }
    }

    trackEvent(eventName: string, params: { [key: string]: string | number | string[] | undefined; }) {
        if (this.trackingEnabled) {
            gtag('event', eventName, params);
        }
    }

    enableTracking(enabled = true) {
        this.trackingEnabled = enabled;
    }
}
