import { Route } from '@angular/router';
import { DiscoverRecipesComponent } from './discover-recipes/discover-recipes.component';
import { DiscoverByTypeComponent } from './discover-by-type/discover-by-type.component';
import { RecipePageComponent } from './recipe-page/recipe-page.component';
import { SearchRecipesComponent } from './search-recipes/search-recipes.component';
import { ImprintComponent } from './imprint/imprint.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AboutUsComponent } from './aboutus/aboutus.component';
import { SavedRecipesComponent } from './saved-recipes/saved-recipes.component';
import { LoginComponent } from './login/login.component';
import { DiscoverByIngredientComponent } from './discover-by-ingredient/discover-by-ingredient.component';

export const appRoutes: Route[] = [
    {component: DiscoverRecipesComponent, path: '', pathMatch: 'full', title: 'Was essen wir jetzt? Zufällige Rezepte und Zutatensuche'},
    {component: DiscoverRecipesComponent, path: 'zufallsgenerator', pathMatch: 'full', title: 'Was essen wir jetzt? Zufällige Rezepte und Zutatensuche'},
    {path: 'zutatensuche', loadChildren: () => import('../../../ionic/src/app/ingredient-search/ingredient-search.module').then(m => m.IngredientSearchModule)},
    {component: DiscoverByTypeComponent, path: 'kategorie/:type', pathMatch: 'full', title: undefined},
    {component: DiscoverByIngredientComponent, path: 'rezepte-mit/:ingredient', pathMatch: 'full', title: undefined},
    {component: RecipePageComponent, path: 'rezepte/:slug', title: undefined},
    {component: SavedRecipesComponent, path: 'gespeicherte-rezepte', pathMatch: 'full', title: 'Gespeicherte Rezepte - Wasessenwir.jetzt'},
    {component: SearchRecipesComponent, path: 'suche/:searchTerm', title: 'Rezeptsuche - Wasessenwir.jetzt'},
    {component: SearchRecipesComponent, path: 'suche', title: 'Rezeptsuche - Wasessenwir.jetzt'},
    {component: ImprintComponent, path: 'impressum', title: 'Impressum - Wasessenwir.jetzt'},
    {component: PrivacyComponent, path: 'datenschutz', title: 'Datenschutz - Wasessenwir.jetzt'},
    {component: AboutUsComponent, path: 'ueber-uns', title: 'Über Uns - Wasessenwir.jetzt'},
    {component: LoginComponent,path: 'login', title: 'Login - Wasessenwir.jetzt'},
    {redirectTo: '', path: '**'},
];
